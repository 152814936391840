$primary-color: #38bbcc;
$body-background:var(--background-color);
$white: #fff;
$primary-text: var(--primary-text);

$menu-about-label-color: $primary-text;
$menu-about-text-color:#5a6171;
$menu-about-sub-page-color: $white;
$menu-about-grid-border-color:#eaeff4;

$wounds-patientDetails-label:#5A6171;
$wounds-patientDetails-value: $primary-text;

$wounds-woundsList-label: $primary-text;
$wounds-woundsList-woundLocation:#5A6171;
$wounds-woundsList-index:#7786A4;
$wounds-woundsList-woundType:#3ABBCC;
$wounds-woundsList-woundBtn-border:#ECEFF5;
$wounds-woundsList-woundBtnActive:rgba(58, 187, 204, 0.15);

$wounds-treatmentList-accordion-background: #f8f9fc;
$wounds-treatmentList-accordion-active: #e7ebf5;
$wounds-treatmentList-accordion-chevron: $primary-text;
$wounds-treatmentList-border: #ECEFF5;

