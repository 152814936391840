@import 'src/assets/styles/theme.style.scss';

.generic-table {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px !important;
  padding: 10px !important;
  box-shadow: 0px 1px 2px #1c9a7e33 !important;

  .table-container {
    flex-grow: 1;
    background-color: #fff;

    table {
      border-collapse: separate;
      border-spacing: 0px 2px;
    }

    tr,
    td,
    th {
      border: 0;
      box-shadow: none;
      white-space: nowrap;
    }

    th {
      font: normal normal 600 12px/14px Montserrat, sans-serif;
      letter-spacing: 0.6px;
      color: var(--th-color) !important;
      text-transform: uppercase;
      opacity: 1;
      background-color: var(--th-background-color);
    }

    .custom-sort-label .MuiTableSortLabel-icon {
      color:var(--th-color) !important;
    }

      tr td {
      font: normal normal 400 14px/16px Montserrat, sans-serif;
      letter-spacing: 0px;
      color:var(--td-color);
      opacity: 1;
      margin-top: 10px;
    }

    tbody {
      .Mui-selected {
        background-color: rgba(58, 187, 204, 0.08) !important;
      }

      tr:nth-of-type(2n + 1) {
        background-color: #f2f5fa;
        .action-button {
          background-color: #e7ebf5;
        }
      }

      tr:hover {
        background-color: #e1e4e9;
        cursor: pointer;
        .action-button {
          background-color: #f3f4fa;
          &:hover {
            background-color: #d6dae3;
          }
        }
      }

      td:first-child,
      th:first-child {
        border-radius: 10px 0 0 10px;
      }

      td:last-child,
      th:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  .action-button {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 5px;
    padding: 0;
    margin-right: 8px;
    font-size: 15px;
  }

  .action-button-group {
    background-color: #e7f7f9;
    color: #3abbcc;
  }
}

.action-menu {
  .MuiPaper-root {
    overflow: visible !important;
    margin-top: 10px !important;
    border-radius: 10px !important;
    box-shadow: none !important;

    ul {
      background-color: #12203e;
      color: #a0a6b2;
      border-radius: 10px;

      li {
        border-radius: 10px;
        margin: 3px 8px;
        padding: 8px;
        min-width: 130px;
        font-weight: 500;
      }

      li:hover {
        background-color: #1c2d53;
        color: #fff;
      }
    }
  }
}
