@import 'theme.style.scss';
@import url('/src/assets/fonts/Montserrat.css');
@import url('/src/assets/fonts/Roboto.css');

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;

  body {
    font-family: 'Montserrat', sans-serif;
    background-color: $body-background !important;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;

    #root {
      height: 100%;

      .page {
        height: 100%;
      }

      .page-container {
        position: relative;
        overflow-wrap: break-word;
        width: inherit;
        height: 100%;
        margin-left: 236px;
        display: flex;
        flex-direction: column;
      }
      .admin-page-container {
        position: relative;
        overflow-wrap: break-word;
        width: inherit;
        height: 100%;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
      }

      .openDrawer-page-container {
        position: relative;
        overflow-wrap: break-word;
        width: inherit;
        height: 100%;
        margin-left: 220px;
        display: flex;
        flex-direction: column;
        // overflow-y: scroll;
      }

      .manage-page-container {
        position: relative;
        overflow-wrap: break-word;
        width: inherit;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .admin-page-container {
        position: relative;
        overflow-wrap: break-word;
        width: inherit;
        height: 100%;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
      }

      .openDrawer-page-container {
        position: relative;
        overflow-wrap: break-word;
        width: inherit;
        height: 100%;
        margin-left: 220px;
        display: flex;
        flex-direction: column;
        // overflow-y: scroll;
      }

      .manage-page-container {
        position: relative;
        overflow-wrap: break-word;
        width: inherit;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .header {
        width: 50px;
        height: 100px;
        color: black;
      }
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

fieldset {
  legend {
    width: unset;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.p24 {
  padding: 24px;
}

.login-default {
  background: url('/assets/images/background/standard-login-bg.jpg') no-repeat center;
  background-size: cover;
  --primary-color: #3abbcc;
}

.login-cr {
  background: url('/assets/images/background/bg_login_cr.jpg') no-repeat center !important;
  background-size: cover;
  --primary-color: #bf87ed;
}

.login-natrox {
  background: url('/assets/images/background/bg_natrox.png') no-repeat center !important;
  background-size: cover;
  --primary-color: #bf87ed;
}

.login-natrox-va {
  background-color: rgb(26,74,129);
  background-repeat: no-repeat !important;
  background-size: cover;
}

// Modal backdrop fade for multiple modals on the body
@for $i from 1 through 6 {
  $zIndexBackdrop:  #{1000 + (5 * $i)};
  $zIndexContent:  #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

@import '~bootstrap/scss/bootstrap';